// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.B6_XhAQV8ze4QkubyG8b{filter:brightness(2)}.nKo2ak34oNypGfToc6RO{background:green;width:6px;height:6px;grid-row:1;grid-column:1;align-self:center;justify-self:center;border-radius:7px;border:2px solid #000}.nKo2ak34oNypGfToc6RO.kXVjHVH4zTeFyssT5HGa{display:none}img{grid-row:1;grid-column:1}img.B6_XhAQV8ze4QkubyG8b{box-shadow:0 0 0 2px;border-radius:50%}`, "",{"version":3,"sources":["webpack://./../../../dev/sharedUiComponents/dist/nodeGraphSystem/nodePort.modules.scss"],"names":[],"mappings":"AAAA,sBACI,oBAAA,CAGJ,sBACI,gBAAA,CACA,SAAA,CACA,UAAA,CACA,UAAA,CACA,aAAA,CACA,iBAAA,CACA,mBAAA,CACA,iBAAA,CACA,qBAAA,CAEA,2CACI,YAAA,CAIR,IACI,UAAA,CACA,aAAA,CAGJ,yBACI,oBAAA,CACA,iBAAA","sourcesContent":[".selected {\r\n    filter: brightness(2);\r\n}\r\n\r\n.pip {\r\n    background: green;\r\n    width: 6px;\r\n    height: 6px;\r\n    grid-row: 1;\r\n    grid-column: 1;\r\n    align-self: center;\r\n    justify-self: center;\r\n    border-radius: 7px;\r\n    border: 2px solid black;\r\n\r\n    &.hidden {\r\n        display: none;\r\n    }\r\n}\r\n\r\nimg {\r\n    grid-row: 1;\r\n    grid-column: 1;\r\n}\r\n\r\nimg.selected {\r\n    box-shadow: 0 0 0 2px;\r\n    border-radius: 50%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selected": `B6_XhAQV8ze4QkubyG8b`,
	"pip": `nKo2ak34oNypGfToc6RO`,
	"hidden": `kXVjHVH4zTeFyssT5HGa`
};
export default ___CSS_LOADER_EXPORT___;
